import { Injectable } from '@angular/core';

type StorageType = 'local' | 'session';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  get<T>(key: string, storage: StorageType = 'local'): T | null {
    const storageInstance = storage === 'local' ? localStorage : sessionStorage;
    const value = storageInstance.getItem(key);
    if (!value) return null;
    try {
      return JSON.parse(value as string) as T;
    } catch {
      return value as T;
    }
  }

  set(key: string, value: unknown, storage: StorageType = 'local'): void {
    const storageInstance = storage === 'local' ? localStorage : sessionStorage;
    storageInstance.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
  }
}
